/**
 * https://www.smashingmagazine.com/2016/02/writing-reusable-components-es6/
 * https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Statements/export
 * https://developers.google.com/web/updates/2017/03/performant-expand-and-collapse
 */
 'use strict';

class ActCollapsible {
    constructor( $el ) {
        this.options = {
            'i18n': {
                'open': 'Open',
                'close': 'Close',
            }
        };
        ActCollapsible.increaseCount();
        this.$el = $el;
        this.options.i18n.open = this.$el.dataset.collapsibleI18nOpen || this.options.i18n.open;
        this.options.i18n.close = this.$el.dataset.collapsibleI18nClose || this.options.i18n.close;
        let $toggle = document.createElement('div');
        $toggle.classList.add('act-collapsible__toggle');
        this.$content = this.$el.querySelector('.act-collapsible__content');
        this.$heading = this.$el.querySelector('.act-collapsible__heading');
        $toggle.append(this.$heading);
        this.$el.innerHTML = '';
        const $wrapper = document.createElement('div');
        $wrapper.classList.add('act-collapsible__wrapper');
        $wrapper.append($toggle);
        $wrapper.append(this.$content);
        this.$el.append($wrapper);
        this.$heading.addEventListener('click', (e) => {
            if (e.target.classList.contains('act-collapsible__button') || e.target.parentNode.classList.contains('act-collapsible__button')) {
                return;
            }
            this.toggleContent()
        });

        this.setId();
        this.createButton();
        this.toggleContent();

    }
    static increaseCount() {
        this.count = this.getCount() + 1;
    }

    static getCount() {
        return this.count || 0;
    }
    #ease (v, pow=4) {
        return 1 - Math.pow(1 - v, pow);
    }
    createAnimation() {
        let $style = document.querySelector('.act-collapsible-animation');
        if ($style) {
        return $style;
        }

        $style = document.createElement('style');
        $style.classList.add('act-collapsible-animation');
        // Figure out the size of the element when collapsed.
        let x = 1;
        let y = 0;
        let collapsedAnimation = '';
        let collapsedInverseAnimation = '';
        let expandedAnimation = '';
        let expandedInverseAnimation = '';

        for (let step = 0; step <= 100; step++) {
            // Remap the step value to an eased one.
            let easedStep = this.#ease(step / 100);

            // Calculate the scale of the element.
            const xScale = x + (1 - x) * easedStep;
            const yScale = y + (1 - y) * easedStep;

            collapsedAnimation += `${step}% {
            transform: scale(${xScale}, ${yScale});
            }`;

            // And now the inverse for the contents.
            const invXScale = 1 / xScale;
            const invYScale = 1 / yScale;
            collapsedInverseAnimation += `${step}% {
            transform: scale(${invXScale}, ${invYScale});
            }`;
            // Calculate the scale of the element.
            const expXScale = 1 + (x - 1) * easedStep;
            const expYScale = 1 + (y - 1) * easedStep;

            expandedAnimation += `${step}% {
            transform: scale(${expXScale}, ${expYScale});
            }`;

            // And now the inverse for the contents.
            const invExpXScale = 1 / expXScale;
            const invExpYScale = 1 / expYScale;
            expandedInverseAnimation += `${step}% {
            transform: scale(${invExpXScale}, ${invExpYScale});
            }`;

        }

        $style.textContent =  `
        @keyframes ActCollapsibleCollapse {
            ${collapsedAnimation}
        }

        @keyframes ActCollapsibleCollapseInverse {
            ${collapsedInverseAnimation}
        }

        @keyframes ActCollapsibleExpand {
            ${expandedAnimation}
        }

        @keyframes ActCollapsibleExpandInverse {
            ${expandedInverseAnimation}
        }`;

        document.head.appendChild($style);

        return $style;
    }
    setId() {

        if (this.id) {
            return;
        }

        if (this.$el.id) {
            this.id = this.$el.id;
        } else {
            this.id = 'act-collapsible-'+ActCollapsible.getCount();
            this.$el.id = this.id;
        }
        this.$content.id = this.$content.id ? this.$content.id : this.id+'-content';
    }
    createButton() {

        if (this.$button) {
            return;
        }

        this.$button = document.createElement('button');
        this.$button.classList.add('act-collapsible__button');
        this.$button.type = 'button';
        const $open = document.createElement('span');
        $open.classList.add('act-collapsible__button-open');
        $open.innerText = this.options.i18n.open;
        const $close = document.createElement('span');
        $close.classList.add('act-collapsible__button-close');
        $close.innerText = this.options.i18n.close;
        this.$button.append($open);
        this.$button.append($close);
        this.$button.setAttribute('aria-controls', this.id);
        this.$button.setAttribute('aria-expanded', true);
        this.$button.addEventListener('click', () => {this.toggleContent()});
        this.$el.querySelector('.act-collapsible__heading').append(this.$button);
        this.$content_button = this.$button.cloneNode(true);
        this.$content_button.addEventListener('click', () => {this.toggleContent()});
        this.$el.querySelector('.act-collapsible__content-inner').append(this.$content_button);
    }
    toggleContent() {
        if (this.$button.getAttribute('aria-expanded') == 'true') {
            this.$el.classList.remove('act-collapsible--expanded');
            this.$el.classList.add('act-collapsible--collapsed');
            this.$button.setAttribute('aria-expanded', false);
            this.$content_button.setAttribute('aria-expanded', false);
        } else {
            this.$el.classList.add('act-collapsible--expanded');
            this.$el.classList.remove('act-collapsible--collapsed');
            this.$button.setAttribute('aria-expanded', true);
            this.$content_button.setAttribute('aria-expanded', true);
        }
     }
}

const collapsibles = document.querySelectorAll('.act-collapsible');
collapsibles.forEach(($el) => {
    new ActCollapsible($el);
});
if (window.location.hash) {
    var hash = window.location.hash.substr(1);
    const openCollaps = document.querySelectorAll('#' + hash);
    openCollaps.forEach(($el) => {
        if ($el.classList.contains('act-collapsible__heading')) {
            const button = $el.getElementsByTagName("button");
            button[0].click();
            $el.focus();
        }
    });
}


window.ActCollapsible = ActCollapsible;